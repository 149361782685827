<template>
  <div
    class="
      widgetContainer
      bg-white
      pullFundsSuccess
      appStatus appStatus--approved
    ">
    <div class="navigationHeader drawer-header-default">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('move_fund_navTitle') }}
        </p>
        <span class="el-icon-close" @click="done" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="move-funds-out-success" :loop="true" />
      <p class="title">
        {{
          $t('pull_fund_transferred_successAmount', {
            value1: '$' + getCompletedPaymentDetails.amount
          })
        }}
      </p>
      <p class="description">
        {{ $t('pay_intra_success_description') }}
      </p>

      <ul class="pullFundsSuccess__details">
        <li>
          <span class="label">
            {{ $t('pay_section_source') }}
          </span>
          <div>
            <p>{{ getSelectedAccount.label }}</p>
          </div>
        </li>
        <li>
          <span class="label">
            {{ $t('pay_section_destination') }}
          </span>
          <div>
            <p>
              {{ getCompletedPaymentDetails.name }}
            </p>
          </div>
        </li>
        <li>
          <span class="label">
            {{ $t('description') }}
          </span>
          <div>
            <p>{{ getCompletedPaymentDetails.description }}</p>
          </div>
        </li>
      </ul>

      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" @click="done" class="welcome__get-started el-button__brand brand">
          {{ $t('done') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: {
    LottieAnimation
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('pay', ['getCompletedPaymentDetails']),
    ...mapGetters('account', ['getSelectedAccount'])
  },
  methods: {
    done() {
      this.drawerClose();
      setTimeout(() => {
        this.goToDashboard();
      }, 0);
    }
  }
};
</script>

<style lang="scss">
.pullFundsSuccess {
  position: relative;

  .description {
    padding-bottom: 30px;
  }

  &__details {
    border: 1px solid var(--branding-sidebar-border);
    border-radius: var(--radius);
    padding: 24px 20px;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      padding-bottom: 18px;
      text-align: left;
      font-size: 14px;
      line-height: 18px;

      .label {
        min-width: 140px;
        color: var(--branding);
      }

      div {
        span {
          color: var(--branding);
        }
      }
    }
  }
}
</style>
